import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";
import {
  ApiResponse,
  Feedback,
  PaymentMethod,
  Rating,
  User,
  dashboardDataInterface,
  getCoachResponse,
  getRatingsResponse,
  getUserDetailsResponse,
  getUserProgressTracking,
  notificationresponse,
} from "../../types/apiresponse";
// import { billingHistory, sortValues } from '../../types/type'

interface getRatingRequest {
  perPage: number;
  page: number;
  type: string;
  filteredValue: string;
  userId: string;
  sortType?: sortValues;
  sortValue?: string;
}

export const getRatings = createAsyncThunk<
  ApiResponse<getRatingsResponse>,
  getRatingRequest
>("UserDashboard/getRatings", async (data) => {
  const res = await axiosClient.get<ApiResponse<getRatingsResponse>>(
    `auth/user-rating?per_page=10&page=${data.page}&type=${data.type}${
      data.sortType === "ascending" ? `&sortBy=-${data.sortValue}` : ``
    }${
      data.sortType === "descending" ? `&sortBy=${data.sortValue}` : ``
    }&filteredVal=${data.filteredValue}&user_id=${data.userId}`
  );
  return res.data;
});

export const getActivties = createAsyncThunk<any, any>(
  "UserDashboard/getActivties",
  async (data) => {
    const res = await axiosClient.get(
      `auth/user-activity?per_page=10&page=${data.page}&type=${data.type}${
        data.sortType === "ascending" ? `&sortBy=-${data.sortValue}` : ``
      }${
        data.sortType === "descending" ? `&sortBy=${data.sortValue}` : ``
      }&filteredVal=${data.filteredValue}&user_id=${data.userId}`
    );
    return res.data;
  }
);

export const getUserAnalytics = createAsyncThunk<
  ApiResponse<getUserProgressTracking>,
  string
>("userDashboard/getAnalytics", async (id) => {
  const res = await axiosClient.get<ApiResponse<any>>(
    `/auth/user-progress-tracking/${id}`
  );
  return res.data;
});

export const getFeedback = createAsyncThunk<ApiResponse<getCoachResponse>, any>(
  "UserDashboard/getFeedback",
  async (data) => {
    const res = await axiosClient.get<ApiResponse<getCoachResponse>>(
      `auth/user-feedbacks?per_page=10&page=${data.page}&type=${
        data.type
      }&user_id=${data.id}${
        data.sortType === "ascending" ? `&sortBy=-${data.sortValue}` : ``
      }${
        data.sortType === "descending" ? `&sortBy=${data.sortValue}` : ``
      }&filteredVal=${data.filteredValue}`
    );
    return res.data;
  }
);

export const getProfile = createAsyncThunk<
  ApiResponse<dashboardDataInterface>,
  string
>("UserDashboard/getProfile", async (id) => {
  const res = await axiosClient.get<ApiResponse<dashboardDataInterface>>(
    `auth/user-profile/${id}`
  );
  return res.data;
});

export const getSupport = createAsyncThunk<any, any>(
  "UserDashboard/getSupport",
  async () => {
    const res = await axiosClient.get("");
    return res.data;
  }
);

export const getPaymentMethods = createAsyncThunk<
  ApiResponse<PaymentMethod[]>,
  string
>("UserDashboard/getPaymentMethods", async (user_id) => {
  const res = await axiosClient.get(`/auth/get-customer-card-user/${user_id}`);
  console.log(res.data);
  return res.data;
});

export const postFreeUser = createAsyncThunk<
  ApiResponse<User>,
  { user_id: string; is_free: boolean }
>("UserDashboard/postFreeUser", async ({ user_id, is_free }) => {
  const res = await axiosClient.post(`/auth/payment-free`, {
    user_id,
    is_free,
  });
  console.log(res.data);
  return res.data;
});

interface getSubscriptionAPI {
  billing_history: billingHistory[];
  total_billing_histroy: number;
  total_page: number;
}

export const getSubscription = createAsyncThunk<
  ApiResponse<getSubscriptionAPI>,
  {
    id: string;
    sortType?: sortValues;
    sortValue?: string;
    filteredValue?: string;
  }
>("UserDashboard/getSubscription", async (data) => {
  const res = await axiosClient.get(
    `auth/payment-history/${data.id}?${
      data.sortType === "ascending" ? `&sortBy=-${data.sortValue}` : ``
    }${data.sortType === "descending" ? `&sortBy=${data.sortValue}` : ``}${
      data.filteredValue ? `&filteredVal=${data.filteredValue}` : ""
    }`
  );
  console.log(res.data);
  return res.data;
});

export const NotificationSend = createAsyncThunk<
  notificationresponse,
  { user_ids: { userid: string }[]; title_text: string; body_text: string }
>(
  "UserDashboard/NotificationSend",
  async ({ user_ids, body_text, title_text }) => {
    const res = await axiosClient.post(`/auth/send-push-notification`, {
      user_ids,
      body_text,
      title_text,
    });
    console.log(res.data);
    return res.data;
  }
);

export const UserResetPassword = createAsyncThunk<
  notificationresponse,
  { password: string; confirm_password: string; user_id: string }
>(
  "UserDashboard/UserResetPassword",
  async ({ confirm_password, password, user_id }) => {
    const res = await axiosClient.post(`/auth/confirm-password-app`, {
      user_id,
      password,
      confirm_password,
    });
    console.log(res.data);
    return res.data;
  }
);
export const UserSendResetPasswordLink = createAsyncThunk<
  notificationresponse,
  { user_id: string }
>("UserDashboard/UserSendResetPasswordLinj", async ({ user_id }) => {
  const res = await axiosClient.post(`/auth/send-resetpasswordLink`, {
    user_id,
  });
  console.log(res.data);
  return res.data;
});

interface getSubscriptionAPI {
  billing_history: billingHistory[];
  total_billing_histroy: number;
  total_page: number;
}

interface UserDashboardSlice {
  getUserProgressTrackingAPIStatus: Status;
  getUserProgressTracking: getUserProgressTracking;
  userRatingData: Rating[];
  userActivityData: any[];
  userFeedbackData: Feedback[];
  currentRatingPage: string;
  currentActivtyPage: string;
  currentFeedbackPage: string;
  activityListPage: number;
  ratingListPage: number;
  feedBackListPage: number;
  totalActivities: number;
  totalRatings: number;
  totalFeedBacks: number;
  userActivties: any;
  paymentmethods: PaymentMethod[];
  ratingSortType: sortValues;
  feedbackSortType: sortValues;
  activitesSortType: sortValues;
  billingSortType: sortValues;
  ratingSortValue: string;
  activitesSortValue: string;
  feedbackSortValue: string;
  billingSortValue: string;
  getRatingAPIStatus: Status;
  getActivtiesAPIStatus: Status;
  getFeedbackAPIStatus: Status;
  getSupportAPIStatus: Status;
  getProfileAPIStatus: Status;
  getSubscriptionAPIStatus: Status;
  getPaymentMethodsAPIStatus: Status;
  getNotificationsendStatus: Status;
  getNotificationsendResponse: notificationresponse;
  getUserResetPasswordStatus: Status;
  getUserResetPasswordResponse: notificationresponse;
  getUserSendResetPasswordLinkStatus: Status;
  getUserSendResetPasswordLinkResponse: notificationresponse;
  dashBoardData: dashboardDataInterface;
  billingData: {
    billing_history: billingHistory[];
    total_billing_histroy: number;
    total_page: number;
  };
}

const initialState: UserDashboardSlice = {
  getUserProgressTrackingAPIStatus: Status.IDLE,
  getUserProgressTracking: {
    first_object: "",
    user: {
      status: true,
      is_free: false,
      aboutFisio: true,
      aboutYou: true,
      appCarousel: true,
      convertFreeSuccess: true,
      convertToFreeTrail: true,
      dailyReminder: true,
      dailyReminderSelection: false,
      initialFreeTrail: false,
      hour: "",
      letsStart: false,
      min: "",
      planOverView: false,
      selectTimezone: false,
      set_daily_reminder: false,
      set_daily_reminder_time: "",
      acknowledgement: false,
      active_plan_name: "",
      skip_reminder: false,
      time: "",
      timezone: "",
      country_flag: "",
      _id: "",
      address: "",
      birthday: "",
      country: "",
      country_id: "",
      country_name: "",
      created_at: "",
      device_type: "",
      email: "",
      fcm_token: "",
      first_name: "",
      full_name: "",
      gender: "",
      group_id: "",
      height: "",
      is_confirm: 0,
      last_login_at: "",
      last_name: "",
      last_recall_card_level_updated_date: "",
      last_updated_training_streak: "",
      max_training_streak: 20,
      mulligan: 4,
      plan: "",
      postal_code: "",
      profile_picture: "",
      registered_at: "",
      time_zone: "",
      timezone_field: "",
      timezone_name: "",
      training_streak: 3,
      updated_at: "",
      user_recall_card_level: 3,
      user_type: "",
      userid: 3434,
      weight: "ss",
      first_object: "",
    },
    user_progress_apply: [],
    user_progress_train: [],
    user_question_answer: [],
  },
  paymentmethods: [],
  billingData: {
    billing_history: [],
    total_billing_histroy: 0,
    total_page: 0,
  },
  activitesSortType: "ascending",
  ratingSortType: "ascending",
  feedbackSortType: "ascending",
  billingSortType: "ascending",
  activitesSortValue: "date",
  feedbackSortValue: "date",
  ratingSortValue: "date",
  billingSortValue: "",
  ratingListPage: 0,
  currentRatingPage: "0",
  totalRatings: 0,
  activityListPage: 0,
  currentActivtyPage: "0",
  totalActivities: 0,
  feedBackListPage: 0,
  currentFeedbackPage: "0",
  totalFeedBacks: 0,
  userActivityData: [],
  getPaymentMethodsAPIStatus: Status.SUCCESS,
  getActivtiesAPIStatus: Status.SUCCESS,
  getFeedbackAPIStatus: Status.SUCCESS,
  getRatingAPIStatus: Status.SUCCESS,
  getProfileAPIStatus: Status.SUCCESS,
  getSubscriptionAPIStatus: Status.SUCCESS,
  getSupportAPIStatus: Status.SUCCESS,
  getNotificationsendStatus: Status.SUCCESS,
  getUserResetPasswordStatus: Status.IDLE,
  getUserSendResetPasswordLinkStatus: Status.SUCCESS,
  userRatingData: [],
  userActivties: {},
  userFeedbackData: [],
  dashBoardData: {
    streak_calender: [],
    feedback: {
      coach_review_count: 0,
      coach_reviews: [],
      shot_check_count: 0,
      shot_checks: [],
      swing_count: 0,
      swings: [],
    },
    activity: {
      apply: [],
      train: [],
      trust: [],
    },
    rating: {
      scc_rating: [],
      session_rating: [],
      weekly_rating: [],
    },
    question_answer: [],
    friend_support: [],
    max_training_streak: 0,
    plan: "",
    rate: "",
    reps: {
      move_drills: 0,
      swing_drills: 0,
      trust_shots: 0,
      recall_cards: 0,
    },
    show_blue: 0,
    training_intention: [],
    training_streak: 0,
    user: {
      status: true,
      is_free: false,
      aboutFisio: true,
      aboutYou: true,
      appCarousel: true,
      convertFreeSuccess: true,
      convertToFreeTrail: true,
      dailyReminder: true,
      dailyReminderSelection: false,
      initialFreeTrail: false,
      hour: "",
      letsStart: false,
      min: "",
      planOverView: false,
      selectTimezone: false,
      set_daily_reminder: false,
      set_daily_reminder_time: "",
      acknowledgement: false,
      active_plan_name: "",
      skip_reminder: false,
      time: "",
      timezone: "",
      country_flag: "",
      _id: "",
      address: "",
      birthday: "",
      country: "",
      country_id: "",
      country_name: "",
      created_at: "",
      device_type: "",
      email: "",
      fcm_token: "",
      first_name: "",
      full_name: "",
      gender: "",
      group_id: "",
      height: "",
      is_confirm: 0,
      last_login_at: "",
      last_name: "",
      last_recall_card_level_updated_date: "",
      last_updated_training_streak: "",
      max_training_streak: 20,
      mulligan: 4,
      plan: "",
      postal_code: "",
      profile_picture: "",
      registered_at: "",
      time_zone: "",
      timezone_field: "",
      timezone_name: "",
      training_streak: 3,
      updated_at: "",
      user_recall_card_level: 3,
      user_type: "",
      userid: 3434,
      weight: "ss",
    },
  },
  getNotificationsendResponse: {
    success: false,
    message: "",
  },
  getUserResetPasswordResponse: {
    success: false,
    message: "",
  },
  getUserSendResetPasswordLinkResponse: {
    message: "",
    success: false,
  },
};

const UserDashboardSlice = createSlice({
  name: "UserDashboard",
  initialState,
  reducers: {
    billingListHandleSort: (state, action) => {
      return {
        ...state,
        billingSortType: action.payload.sortType,
        billingSortValue: action.payload.sortValue,
      };
    },
    ratingListHandleSort: (state, action) => {
      return {
        ...state,
        ratingSortType: action.payload.sortType,
        ratingSortValue: action.payload.sortValue,
      };
    },
    activityListHandleSort: (state, action) => {
      return {
        ...state,
        activitesSortType: action.payload.sortType,
        activitesSortValue: action.payload.sortValue,
      };
    },
    feedbackListHandleSort: (state, action) => {
      return {
        ...state,
        feedbackSortType: action.payload.sortType,
        feedbackSortValue: action.payload.sortValue,
      };
    },
    ratingListSort: (state) => {
      return {
        ...state,
        sortType:
          state.ratingSortType === "ascending" ? "descending" : "ascending",
      };
    },
    activityListSort: (state) => {
      return {
        ...state,
        sortType:
          state.activitesSortType === "ascending" ? "descending" : "ascending",
      };
    },
    clearFeedBackData: (state) => {
      state.userFeedbackData = [];
    },
    clearRatingData: (state) => {
      state.userRatingData = [];
    },
    clearActivities: (state) => {
      state.userActivityData = [];
    },
    clearDashboardData: (state) => {
      state.dashBoardData = {
        streak_calender: [],
        feedback: {
          coach_review_count: 0,
          coach_reviews: [],
          shot_check_count: 0,
          shot_checks: [],
          swing_count: 0,
          swings: [],
        },
        activity: {
          apply: [],
          train: [],
          trust: [],
        },
        rating: {
          scc_rating: [],
          session_rating: [],
          weekly_rating: [],
        },
        question_answer: [],
        friend_support: [],
        max_training_streak: 0,
        plan: "",
        rate: "",
        reps: {
          move_drills: 0,
          swing_drills: 0,
          trust_shots: 0,
          recall_cards: 0,
        },
        show_blue: 0,
        training_intention: [],
        training_streak: 0,
        user: {
          status: true,
          is_free: false,
          aboutFisio: true,
          aboutYou: true,
          appCarousel: true,
          convertFreeSuccess: true,
          convertToFreeTrail: true,
          dailyReminder: true,
          dailyReminderSelection: false,
          initialFreeTrail: false,
          hour: "",
          letsStart: false,
          min: "",
          planOverView: false,
          selectTimezone: false,
          set_daily_reminder: false,
          set_daily_reminder_time: "",
          acknowledgement: false,
          active_plan_name: "",
          skip_reminder: false,
          time: "",
          timezone: "",
          country_flag: "",
          _id: "",
          address: "",
          birthday: "",
          country: "",
          country_id: "",
          country_name: "",
          created_at: "",
          device_type: "",
          email: "",
          fcm_token: "",
          first_name: "",
          full_name: "",
          gender: "",
          group_id: "",
          height: "",
          is_confirm: 0,
          last_login_at: "",
          last_name: "",
          last_recall_card_level_updated_date: "",
          last_updated_training_streak: "",
          max_training_streak: 0,
          mulligan: 0,
          plan: "",
          postal_code: "",
          profile_picture: "",
          registered_at: "",
          time_zone: "",
          timezone_field: "",
          timezone_name: "",
          training_streak: 3,
          updated_at: "",
          user_recall_card_level: 3,
          user_type: "",
          userid: 3434,
          weight: "ss",
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRatings.pending, (state) => {
        state.getRatingAPIStatus = Status.LOADING;
      })
      .addCase(getRatings.fulfilled, (state, action) => {
        state.getRatingAPIStatus = Status.SUCCESS;
        state.userRatingData = action.payload.data.ratings;
        state.ratingListPage = action.payload.data.total_page;
        state.totalRatings = action.payload.data.total_ratings;
        state.currentRatingPage = action.payload.data.current_page;
      })
      .addCase(getRatings.rejected, (state) => {
        state.getRatingAPIStatus = Status.ERROR;
      })
      .addCase(getActivties.pending, (state) => {
        state.getActivtiesAPIStatus = Status.LOADING;
      })
      .addCase(getActivties.fulfilled, (state, action) => {
        state.getActivtiesAPIStatus = Status.SUCCESS;
        state.userActivityData = action.payload.data.data;
        state.activityListPage = action.payload.data.total_page;
        state.totalActivities = action.payload.data.total_activites;
        state.currentActivtyPage = action.payload.data.current_page;
      })
      .addCase(getActivties.rejected, (state) => {
        state.getActivtiesAPIStatus = Status.ERROR;
      })
      .addCase(getFeedback.pending, (state) => {
        state.getFeedbackAPIStatus = Status.LOADING;
      })
      .addCase(getFeedback.fulfilled, (state, action) => {
        state.getFeedbackAPIStatus = Status.SUCCESS;
        state.userFeedbackData = action.payload.data.data;
        state.feedBackListPage = action.payload.data.total_page;
        state.totalFeedBacks = action.payload.data.total_records;
        state.currentFeedbackPage = action.payload.data.current_page;
      })
      .addCase(getFeedback.rejected, (state) => {
        state.getFeedbackAPIStatus = Status.ERROR;
      })
      .addCase(getSubscription.pending, (state) => {
        state.getSubscriptionAPIStatus = Status.LOADING;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.getSubscriptionAPIStatus = Status.SUCCESS;
        state.billingData = action.payload.data;
      })
      .addCase(getSubscription.rejected, (state) => {
        state.getSubscriptionAPIStatus = Status.ERROR;
      })
      .addCase(getProfile.pending, (state) => {
        state.getProfileAPIStatus = Status.LOADING;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileAPIStatus = Status.SUCCESS;
        state.dashBoardData = action.payload.data;
      })
      .addCase(getProfile.rejected, (state) => {
        state.getProfileAPIStatus = Status.ERROR;
      })
      .addCase(getSupport.pending, (state) => {
        state.getSupportAPIStatus = Status.LOADING;
      })
      .addCase(getSupport.fulfilled, (state) => {
        state.getSupportAPIStatus = Status.SUCCESS;
      })
      .addCase(getSupport.rejected, (state) => {
        state.getSupportAPIStatus = Status.ERROR;
      })
      .addCase(getPaymentMethods.pending, (state) => {
        state.getPaymentMethodsAPIStatus = Status.LOADING;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.getPaymentMethodsAPIStatus = Status.SUCCESS;
        state.paymentmethods = action.payload.data;
      })
      .addCase(getPaymentMethods.rejected, (state) => {
        state.getPaymentMethodsAPIStatus = Status.ERROR;
      })
      .addCase(postFreeUser.pending, (state) => {
        state.getPaymentMethodsAPIStatus = Status.LOADING;
      })
      .addCase(postFreeUser.fulfilled, (state, action) => {
        state.getPaymentMethodsAPIStatus = Status.SUCCESS;
        state.dashBoardData.user.is_free = action.payload.data.is_free;
      })
      .addCase(postFreeUser.rejected, (state) => {
        state.getPaymentMethodsAPIStatus = Status.ERROR;
      })
      .addCase(getUserAnalytics.pending, (state) => {
        state.getUserProgressTrackingAPIStatus = Status.LOADING;
      })
      .addCase(getUserAnalytics.fulfilled, (state, action) => {
        state.getUserProgressTrackingAPIStatus = Status.SUCCESS;
        state.getUserProgressTracking.user = action.payload.data.user;
        state.getUserProgressTracking.user_progress_apply =
          action.payload.data.user_progress_apply;
        state.getUserProgressTracking.user_progress_train =
          action.payload.data.user_progress_train;
      })
      .addCase(getUserAnalytics.rejected, (state) => {
        state.getUserProgressTrackingAPIStatus = Status.ERROR;
      })
      .addCase(NotificationSend.pending, (state) => {
        state.getNotificationsendStatus = Status.LOADING;
      })
      .addCase(NotificationSend.fulfilled, (state, action) => {
        state.getNotificationsendStatus = Status.SUCCESS;
        state.getNotificationsendResponse = action.payload;
      })
      .addCase(NotificationSend.rejected, (state) => {
        state.getNotificationsendStatus = Status.ERROR;
      })
      .addCase(UserResetPassword.pending, (state) => {
        state.getUserResetPasswordStatus = Status.LOADING;
      })
      .addCase(UserResetPassword.fulfilled, (state, action) => {
        state.getUserResetPasswordStatus = Status.SUCCESS;
        state.getUserResetPasswordResponse = action.payload;
      })
      .addCase(UserResetPassword.rejected, (state) => {
        state.getUserResetPasswordStatus = Status.ERROR;
      })
      .addCase(UserSendResetPasswordLink.pending, (state) => {
        state.getUserSendResetPasswordLinkStatus = Status.LOADING;
      })
      .addCase(UserSendResetPasswordLink.fulfilled, (state, action) => {
        state.getUserSendResetPasswordLinkStatus = Status.SUCCESS;
        state.getUserSendResetPasswordLinkResponse = action.payload;
      })
      .addCase(UserSendResetPasswordLink.rejected, (state) => {
        state.getUserSendResetPasswordLinkStatus = Status.ERROR;
      });
  },
});

export const {
  ratingListHandleSort,
  billingListHandleSort,
  clearFeedBackData,
  clearDashboardData,
  clearActivities,
  clearRatingData,
  feedbackListHandleSort,
  activityListHandleSort,
  ratingListSort,
  activityListSort,
} = UserDashboardSlice.actions;

export default UserDashboardSlice.reducer;
